import {environments} from "@apps/Shared/Services/Axios/Environment/Environments";
import { useEffect } from 'react';

const getAssetsBaseUrl = (): string => {
    const currentHost = window.location.host;

    // Find the environment that matches the current host
    const currentEnvironment = environments.find((env) =>
        env.urls.site.includes(currentHost)
    );

    if (currentEnvironment) {
        return currentEnvironment.urls.site; // Use `urls.site` as the base for assets
    }

    console.error('Environment not found for host:', currentHost);
    return ''; // Return empty string or a fallback value if no environment matches
};

const getAssetsFileUrl = (fileName: string): string => {
    const basePath = getAssetsBaseUrl();
    const assetPath = `/assets/css/${fileName}`;
    if (assetPath) {
        return basePath + assetPath;
    } else {
        console.error(`Failed to find webpack asset, filename: ${fileName}`);
        return '';
    }
};

export const useDynamicCss = (href: string): void => {
    useEffect(() => {
        if (!href) {
            console.error('CSS href is required.');
            return;
        }

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = getAssetsFileUrl(href);

        // Append the link to the <head>
        document.head.appendChild(link);

        // Cleanup function to remove the link on component unmount
        return () => {
            document.head.removeChild(link);
        };
    }, [href]); // Dependency array ensures the effect runs when `href` changes
};