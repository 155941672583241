import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IModalProps, ModalSize } from './types'
import { targetIsDatepicker } from '@sharedComponents/InputDatePicker/utils'
import { KEYS } from '../../Constants/KeyCodes'
import { addClass, removeClass } from '@frontendJs/util/helpers'
import { targetIsScrollbar } from './utils'

const ModalPortal = (props: IModalProps) => {
    const modalDialogRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()
    const OPEN_CSS_CLASS = 'modal--show'

    function HandleClose() {
        if (props.handleClose) {
            props.handleClose()
        }

        if (props.onAfterClose) {
            props.onAfterClose()
        }
    }

    function HandleKeyUp(event: any) {
        switch (event.keyCode) {
            case KEYS.esc:
                HandleClose()
                break
            default:
                break
        }
    }

    const isMultipleModalsOpen = () => {
        if (document) {
            const allModals = document.querySelectorAll('.modal--show')
            const nrOfOpenModals = allModals && allModals.length
            return !!(nrOfOpenModals > 1)
        }
        return false
    }

    function HandleClickOutside(event: any) {
        if (
            targetIsScrollbar(event) ||
            targetIsDatepicker(event.target) ||
            isMultipleModalsOpen()
        ) {
            return
        }
        if (
            modalDialogRef.current &&
            !modalDialogRef.current.contains(event.target)
        ) {
            HandleClose()
        }
    }

    useEffect(() => {
        if (!props.open) return

        // Events
        if (props.shouldCloseOnEsc) {
            document.addEventListener('keyup', HandleKeyUp)
        }

        if (props.shouldCloseOnOverlayClick) {
            document.addEventListener('mouseup', HandleClickOutside)
        }

        return () => {
            if (!props.open) return

            if (props.shouldCloseOnEsc) {
                document.removeEventListener('keyup', HandleKeyUp)
            }

            if (props.shouldCloseOnOverlayClick) {
                document.removeEventListener('mouseup', HandleClickOutside)
            }
        }
    }, [props, props.open])

    useEffect(() => {
        if (props.open) {
            addClass(document.body, 'disable-scroll')
            modalDialogRef.current?.focus()
        } else {
            removeClass(document.body, 'disable-scroll')
        }
    }, [props.open])

    function RenderModal() {
        return (
            <div
                tabIndex={-1}
                role={props.role}
                className={`modal ${props.className || ''} ${
                    props.open ? OPEN_CSS_CLASS : ''
                }`}
                aria-hidden={!props.open}
            >
                <div className={`modal__dialog --${props.size || ModalSize.Large}`}
                    role="document"
                    ref={modalDialogRef}
                    tabIndex={props.open ? 0 : -1}
                >
                    <div className={`modal__content ${props.noPadding? 'modal__content--noPadding' : ''}`}>
                        {props.children}
                        <button
                            className='modal__close-button'
                            hidden={props.disableCloseButton}
                            onClick={HandleClose}
                            aria-label={t('modal.closeModal')}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    if (typeof document === 'undefined') {
        return <></>
    }

    return ReactDOM.createPortal(RenderModal(), props.domNode || document.body)
}

ModalPortal.defaultProps = {
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    role: 'dialog',
}

export default ModalPortal