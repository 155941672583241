import { ButtonLinkBlockProps } from './types';
import { ButtonType } from '../Button/types';
import { ExternalVideoLayout } from '../ExternalVideo/types';
import { isLink } from './utils';
import { LinkType } from '../Links/types';
import { ModalSize } from '../Modal/types';
import Button from '../Button';
import ExternalVideo from '../ExternalVideo/ExternalVideo';
import Icon from '../Icon';
import InlineLink from '../Links/InlineLink';
import Modal from '../Modal';
import React, { useCallback, useState } from 'react';
import {useDynamicCss} from "@frontendUtils/assets-helper";

function navigateToExternalUrl(url: string) {
    window.open(url, '_blank');
}

function navigateToInternalUrl(url: string) {
    window.location.href = url;
}

function clickVideo(url: string, setDisplayVideoModal: Function) {
    setDisplayVideoModal(url)
}

function isExternal(props: ButtonLinkBlockProps): boolean {
    return !!props.ExternalLink;
}

function isDocumentLink(props: ButtonLinkBlockProps) {
    return props.DocumentLink && props.DocumentLink.Id;
}

function onClick(props: ButtonLinkBlockProps, setDisplayVideoModal: Function) {
    if (props.ExternalLink) {
        return navigateToExternalUrl(props.ExternalLink);
    }
    if (isDocumentLink(props)) {
        
        const url = props.DocumentLink && props.DocumentLink.Url;

        if (!url) {
            return;
        }
        return navigateToExternalUrl(url);
    }
    if (props.Link && props.Link.Expanded && props.Link.Expanded.RelativePath) {
        return navigateToInternalUrl(props.Link.Expanded.RelativePath);
    }
    if (props.VideoUrl) {
        return clickVideo(props.VideoUrl, setDisplayVideoModal);
    }

    return
}

function renderButtonIcon(props: ButtonLinkBlockProps) {
    if (isExternal(props)) {
        return <Icon iconName={'external-link'} size={18}></Icon>;
    }
    if (isDocumentLink(props)) {
        return <Icon iconName={'download'} size={18}></Icon>;
    }
    if (props.VideoUrl) {
        return <Icon iconName={'play-outlined'} size={18}></Icon>;
    }

    return;
}

function renderButtonText(props: ButtonLinkBlockProps) {
    const icon = renderButtonIcon(props);

    return (
        <>
            <span>{props.LinkText}</span>
            {icon}
        </>
    );
}

function mapProps(
    props: ButtonLinkBlockProps,
    setDisplayVideoModal: Function
) {
    return {
        className: 'button-link-block',
        text: renderButtonText(props),
        type: Object.values(ButtonType)[Number(props.ButtonClass)],
        onClick: onClick.bind(
            null,
            props,
            setDisplayVideoModal
        ),
    }
}

function mapLinkProps(props: ButtonLinkBlockProps) {
    return {
        hideIcon: true,
        text: props.LinkText,
        type: LinkType.Intern,
        url: '',
    }
}

const ButtonLinkBlock = (props: ButtonLinkBlockProps) => {
    useDynamicCss('button-link-block.css')
    const [blockRef, setBlockRef] = useState<any>(undefined);
    const [displayVideoModal, setDisplayVideoModal]: [
        boolean | string,
        Function
    ] = useState(false);
    const [displayPopup, setDisplayPopup] = useState<boolean>(false);

    const buttonProps = mapProps(props, setDisplayVideoModal);
    const linkProps = mapLinkProps(props);

    const onClickLink = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDisplayPopup(true);
    }

    const onBlockRef = useCallback((node: any) => {
        if (node !== null && !blockRef) {
            setBlockRef(node);
        }
    }, []);
    
    return (
        <div className='button-link-block' ref={onBlockRef}>
            {isLink(props.ButtonClass) ? (
                <InlineLink {...linkProps} onClick={onClickLink} />
            ) : (
                <Button {...buttonProps} role="link" />
            )}
            {props.VideoUrl && (
                <Modal
                    open={typeof displayVideoModal === 'string'}
                    handleClose={setDisplayVideoModal.bind(null, false)}
                    size={ModalSize.ExtraLarge}
                    noPadding={true}
                >
                    {typeof displayVideoModal === 'string' && (
                        <ExternalVideo
                            url={displayVideoModal}
                            layout={ExternalVideoLayout.Default}
                            autoPlay={true}
                        />
                    )}
                </Modal>
            )}
            {props.PopupContent && blockRef && (
                <Modal
                    open={displayPopup}
                    handleClose={() => setDisplayPopup(false)}
                    domNode={blockRef}
                    shouldCloseOnOverlayClick={false}
                >
                    <div className="button-link-block-popup-content"
                         dangerouslySetInnerHTML={{ __html: props.PopupContent.Html }}>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default ButtonLinkBlock;
